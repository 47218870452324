<template>
	<div style="background: #fff;padding: 15px;">
		<div>
			<div class="filter-container">
				<div class="filter-item">
					<div style="display: flex;align-items: center;">
						<div class="label" style="white-space: nowrap;font-weight: 700;">关键字:</div>
						<el-input v-model="searchKey" @keyup.enter.native="goodsFilter" placeholder="手机号、微信昵称、姓名" style="width: 300px;"></el-input>
						<div class="label" style="white-space: nowrap;font-weight: 700;margin-left: 15px;">归属门店: </div>
						<el-select v-model="activeType" clearable>
							<el-option label="全部" :value="null"></el-option>
							<el-option v-for="item in doorList" :key="item.Id" :label="item.ShopName" :value="item.Id">
							</el-option>
						</el-select>
						<div>
							<el-button type="primary" style="width:90px;margin-left: 15px;" size="small" @click="goodsFilter">查询</el-button>
						</div>
					</div>
				</div>
			</div>

			<div class="table-container">
				<el-table :data="distStatistLists" style="width: 100%;" v-loading="loading">
					<el-table-column label="员工" :key='1'>
						<template slot-scope="scope">
							<div style="display: flex;align-items: center;">
								<img :src="scope.row.WxHeadUrl ? scope.row.WxHeadUrl  : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
								<div style="margin-left: 10px;height: 50px;line-height: 50px;">{{scope.row.EmployeeName ? scope.row.EmployeeName :scope.row.Phone }}</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="Phone" label="手机号" :key='2'></el-table-column>
					<el-table-column prop="ShopName" label="归属门店" :key='3'></el-table-column>
					<el-table-column prop="TotalBalance" label="累计提成" :key='4'></el-table-column>
					<el-table-column prop="DrawedBalance" label="累计提现" :key='5'></el-table-column>
					<el-table-column prop="DrawingBalance" label="提现中" :key='6'></el-table-column>
					<el-table-column prop="UnfinishBalance" label="待结算" :key='7'></el-table-column>
					<el-table-column prop="WaitDrawBalance" label="待提现" :key='8'></el-table-column>
					<el-table-column prop="CancelBalance" label="已扣除" :key='9'></el-table-column>
					<el-table-column label="操作" width="150px" :key='10'>
						<template slot-scope="scope">
							<!-- <el-button type="text" @click="achievementDetail(scope.row)">收益明细</el-button> -->
							<el-button type="text" @click="achievementDetail(scope.row)">提成明细</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div style="display:flex;justify-content:space-between;align-items:center;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
					</div>
					<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
					 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total">
					</el-pagination>
				</div>
			</div>
		</div>

		<!--  -->
		<el-dialog :visible.sync="achievementShow" width="80%" title="店员提成明细">
			<div>
				<div class="filter-container">
					<div style="display: flex;align-items: center;">
						<label class="label" style="width: 70px;white-space: nowrap;">发放时间: </label>
						<el-date-picker v-model="startTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions'
						 placeholder="开始时间">
						</el-date-picker>
						<span style="margin: 0 10px;">~</span>
						<el-date-picker v-model="endTime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions'
						 placeholder="结束时间">
						</el-date-picker>
						<label class="label" style="white-space: nowrap;margin-left: 15px;">提成状态: </label>
						<el-select v-model="authType" clearable>
							<el-option :value="null" label="全部"></el-option>
							<el-option v-for="item in activityStateList" :key="item.id" :label="item.type" :value="item.id">
							</el-option>
						</el-select>
						<div>
							<el-button type="primary" style="margin-left:30px;width: 90px;" size="small" @click="goodsSearch">查询</el-button>
						</div>
					</div>
				</div>
				<div style="font-size: 14px;color: #101010;margin-top: 10px;background: #F0F2F5;padding: 15px 10px;margin-bottom: 10px;">
					<span style="margin-right: 100px;">累计收益:{{totalTopDetail.TotalBalance}}</span>
					<span style="margin-right: 100px;">待结算收益:{{totalTopDetail.UnfinishBalance }}</span>
					<span style="margin-right: 100px;">已结算收益:{{totalTopDetail.WaitDrawBalance }}</span>
					<span>已扣除收益:{{totalTopDetail.CancelBalance }}</span>
				</div>
				<!--  -->
				<div class="table-container">
					<el-table max-height="500" :data="distDetailLists" style="width: 100%;" v-loading="loading2">
						<el-table-column label="员工" :key='11' width="200px">
							<template slot-scope="scope">
								<div style="display: flex;align-items: center;">
									<img :src="scope.row.WxHeadUrl ? scope.row.WxHeadUrl  : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
									<div style="margin-left: 10px;height: 50px;line-height: 50px;">{{scope.row.EmployeeName ? scope.row.EmployeeName :scope.row.Phone }}</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="Phone" label="手机号" :key='12'></el-table-column>
						<el-table-column prop="AddTime" label="发放时间" :key='13'></el-table-column>
						<el-table-column prop="AwardMoney" label="提成" :key='14'></el-table-column>
						<el-table-column prop="StateValue" label="状态" :key='15'></el-table-column>
						<el-table-column label="订单编号" width="200px" :key='16'>
							<template slot-scope="scope">
								<div style="white-space: nowrap;color: #409EFF;">{{scope.row.OrderNo}}
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="ProductName" label="商品" :key='17'></el-table-column>
						<el-table-column prop="CommissionRateValue" label="收益比" :key='18'></el-table-column>
						<el-table-column prop="Remark" label="收益备注" :key='19'></el-table-column>
					</el-table>
					<div style="display:flex;justify-content:space-between;align-items:center;">
						<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;">
						</div>
						<el-pagination v-if="Total1" style="margin-top:20px;float:right;" @size-change="handleSizeChange1"
						 @current-change="handleCurrentChange1" :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]"
						 :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper" :total="Total1">
						</el-pagination>
					</div>
				</div>
			</div>

		</el-dialog>

	</div>
</template>

<script>
	import config from '@/config/index'
	import {
		employeecommissionList,
		employeedetailCollect,
		employeedetailList
	} from '@/api/wyUsedInterface.js'

	import {
		shopdroplist
	} from '@/api/api.js'
	export default {
		data() {
			return {
				distDetailLists:[],
				groupData: [],
				groupData2: [],
				activityStateList: [{
					id: 0,
					type: '待结算'
				}, {
					id: 1,
					type: '已结算'
				}, {
					id: 2,
					type: '已失效'
				}],
				doorList: [],
				searchKey: '',
				loading: false,
				editVisable: false,
				groupName: "",
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				authType: null,
				activeType: null,
				achievementShow: false,
				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				totalTopDetail: {},
				currentRow: {},
				endTime: '',
				startTime: '',
				loading2: false,
				distStatistLists:[],
				imgUrl: config.IMG_BASE,
				defaultHeader:config.DEFAULT_HEADER
			};
		},
		beforeMount() {
			this.getDataList()
			this.getDoorList()
			this.initTime()
		},
		methods: {
			initTime(){
				let time = new Date()
				let thirtyBefore = new Date(time - 1000 * 60 * 60 * 24 * 30 )
				let start = new Date(new Date(thirtyBefore).toLocaleDateString()).getTime();
				let end = new Date(new Date(new Date().toLocaleDateString()).getTime()+24*60*60*1000-1)
				this.startTime = new Date( start)
				this.endTime = end
			},
			async getDataList() {
				this.loading = true
				try {
					let data = {
						Keywords: this.searchKey,
						ShopId: this.activeType ,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize, // 取的数据
					}
					let result = await employeecommissionList(data)
					this.distStatistLists = result.Result.Results
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
				this.loading = false
			},
			async getDoorList() {
				try {
					let doorResult = await shopdroplist({})
					this.doorList = this.doorList.concat(doorResult.Result)

				} catch (e) {
					//TODO handle the exception
				}
			},
			goodsSearch() {
				this.currentPage1 = 1
				this.getDetailData()
			},
			goodsFilter() {
				this.currentPage1 = 1
				this.getDataList()
			},


			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getDataList();
			},

			// 翻页
			handleCurrentChange(val) {
				//console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getDataList();
			},

			// 切换显示条数
			handleSizeChange1(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize1 = val;
				this.getDetailData()
			},

			// 翻页
			handleCurrentChange1(val) {
				//console.log(`当前页: ${val}`);
				this.currentPage1 = val;
				this.getDetailData()
			},
			achievementDetail(row) {
				this.$router.push({
					path: '/distribution/door/drawDetailpage',
					query: {
						keyId: 2,
						employPhone:row.Phone
					}
				});
// 				this.currentRow = row
// 				this.currentPage1 = 1
// 				this.getDetailData()
// 				this.achievementShow = true
			},
			async getDetailData() {
				this.loading2 = true
				try {
					let data = {
						MallEmployeeId: this.currentRow.MallEmployeeId
					}
					let result = await employeedetailCollect(data)
					this.totalTopDetail = result.Result

					let data2 = {
						MallEmployeeId: this.currentRow.MallEmployeeId,
						StartTime: this.startTime,
						EndTime: this.endTime,
						State: this.authType,
						Skip: (this.currentPage1 - 1) * this.pageSize1,
						Take: this.pageSize1, // 取的数据
					}
					let result2 = await employeedetailList(data2)
					this.distDetailLists = result2.Result.Results
					this.Total1 = result2.Result.Total
				} catch (e) {
					//TODO handle the exception
				}
				this.loading2 = false
			}
		},
		computed: {
			//日期选择器限制选择
			minOptions: function() {
				let limitTime = this.startTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(limitTime)
						}
						// else{
						//            return time < Date.now()
						//          }
					}
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(limitTime)
								// ||time < Date.now()
							)
						}
					}
				}
			}
		},
	}
</script>

<style lang="less" scoped>
</style>
